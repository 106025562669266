import type { AppProps } from 'next/app'
import { Auth0Provider } from '@auth0/auth0-react'
import Layout from '../components/Layout'
import Script from 'next/script'
import { AppProvider } from '../AppContext'
import { useAbility } from '../lib/hooks/api'
import { ReactNode } from 'react'
import { AbilityContext } from '../lib/can'

const AbilityComponent = ({ children } : {children: ReactNode}) => {
  const { ability } = useAbility()

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  )
}

const App = ({ Component, pageProps }: AppProps) => {

  return (
    <>
      <Script
        src="https://browser.sentry-cdn.com/6.17.2/bundle.min.js"
        integrity="sha384-iweRxhvAFbKxdRFQ1uPsP+XFOxTW74MyMjjh80MaNFQor+3zNOkbY9izqBiwbpYN"
        crossOrigin="anonymous"
      />
      <AppProvider>
        <Auth0Provider
          domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN as string}
          clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string}
          audience={process.env.NEXT_PUBLIC_AUTH0_AUDIENCE as string}
          redirectUri={
            (typeof window !== 'undefined' && window.location.origin) || ''
          }
          useRefreshTokens={true}
        >
          <AbilityComponent>
            {Layout(<Component {...pageProps} />, pageProps)}
          </AbilityComponent>
            
        </Auth0Provider>
      </AppProvider>
    </>
  )
}

export default App
