// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  // % of Performance Monitoring Traces to Send to Sentry
  tracesSampleRate:
    process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT == 'production' ? 0.2 : 1.0,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  beforeSend(event) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      Sentry.showReportDialog({ eventId: event.event_id })
    }
    return event
  },
})
