import React, { ReactChild, ReactFragment, ReactPortal, useState } from 'react'
import { styled, createTheme, ThemeProvider, Theme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import MuiDrawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Badge from '@mui/material/Badge'
import Container from '@mui/material/Container'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import NotificationsIcon from '@mui/icons-material/Notifications'
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import WindowRoundedIcon from '@mui/icons-material/WindowRounded';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import {Can} from '../lib/can'


import Link from 'next/link'
import sanity from '../lib/sanity'
import { SearchForm } from './searchForm'

const drawerWidth = 240

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }: { theme: Theme; open: boolean }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}))

const myTheme = createTheme({
  palette: {
    primary: {
      main: '#ea1010',
    },
    secondary: {
      main: '#f50057',
    },
  },
})

const Layout = (
  page: ReactChild | ReactFragment | ReactPortal,
  { title }: LayoutProps,
) => {
  const [open, setOpen] = useState(false)
  const toggleDrawer = () => setOpen(!open)

  return (
    <ThemeProvider theme={myTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" theme={myTheme} open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {title}
            </Typography>
            <Can I='read' a='all'>
              <SearchForm></SearchForm>
            </Can>
            
            <IconButton color="inherit">
              <Badge badgeContent={0} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Link href="/account" passHref>
              <IconButton color='inherit'>
                <Badge badgeContent={0} color="secondary">
                  <AccountCircleRoundedIcon></AccountCircleRoundedIcon>
                </Badge>
              </IconButton>
            </Link>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List>
            <Can I='read' a='all'>
              <Link href="/" passHref>
                <ListItem button>
                  <ListItemIcon>
                    <PublicRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItem>
              </Link>
              <Link href="/tables" passHref>
                <ListItem button>
                  <ListItemIcon>
                    <WindowRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Tables" />
                </ListItem>
              </Link>
              <Link href="/reports" passHref>
                <ListItem button>
                  <ListItemIcon>
                    <InsertDriveFileRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Reports" />
                </ListItem>
              </Link>
            </Can>
            <Can I='upload' a='data'>
              <Link href="/import" passHref>
                <ListItem button>
                  <ListItemIcon>
                    <UploadFileRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Import" />
                </ListItem>
              </Link>
            </Can>
            
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container style={{marginLeft:'0px'}} maxWidth={false} sx={{ mt: 4, mb: 4 }}>
            {page}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export interface LayoutProps {
  title: string
}

export const getStaticProps = async (): Promise<{ props: LayoutProps }> => ({
  props: await sanity.fetch(
    `*[_type == 'settings' && _id == 'settings'][0] {
        title
      }`,
  ),
})

export default Layout
