import { useAuth0 } from '@auth0/auth0-react'
import { Ability } from '@casl/ability'
import axios from 'axios'
import { useEffect, useState } from 'react'
import useSWR, { SWRResponse } from 'swr'
import { SunderlandData,BoundariesData } from '../types'

const fetcher = async (accessToken: string, url: string) => {
  const api = axios.create({
    baseURL:
      process.env.NODE_ENV == 'production'
        ? '/api/'
        : 'http://127.0.0.1:8787/api/',
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  })

  return await api.get(url).then((r) => r.data)
}

export const useApi = () => {
  const [accessToken, setAccessToken] = useState<string | null>(null)
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    getAccessTokenSilently().then((token) => setAccessToken(token))
  }, [getAccessTokenSilently])

  if (!accessToken) {
    return {
      isLoading: true,
    }
  }

  const api = axios.create({
    baseURL:
      process.env.NODE_ENV == 'production'
        ? '/api/'
        : 'http://127.0.0.1:8787/api/',
    headers: {
      authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
  })

  return {
    isLoading: false,
    api,
  }
}

const useGetApi = (url: string) => {
  const [accessToken, setAccessToken] = useState<string | null>(null)
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    getAccessTokenSilently().then((token) => setAccessToken(token))
  }, [getAccessTokenSilently])

  return useSWR(accessToken ? [accessToken, url] : null, fetcher)
}

export const useAbility = () => {
  const { data } = useGetApi('/casl/abilities')

  return { ability: new Ability(data || []) }
}

type Schema = Record<string, unknown>
type Schemas = Record<string, unknown>

export const useGetSchemas = (): SWRResponse<Schemas, unknown> =>
  useGetApi('/uploads/options')

export const useGetSchema = (schema: unknown): SWRResponse<Schema, unknown> =>
  useGetApi(`/uploads/${schema}/schema`)

export const useQueryRecords = (q : Record<string, string>) : SWRResponse<SunderlandData[], unknown> => useGetApi('/query/all?' + (new URLSearchParams(q).toString()))
export const useColours = () => useGetApi('/query/all/colours')

export const useBoundaries = (): SWRResponse<BoundariesData[], unknown> =>
  useGetApi('/misc/wards_boundaries')