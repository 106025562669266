import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@mui/material'
import EventRoundedIcon from '@mui/icons-material/EventRounded'
import SearchIcon from '@mui/icons-material/Search'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import enLocale from 'date-fns/locale/en-GB'
import { useAppContext } from '../AppContext'
import { useGetSchemas } from '../lib/hooks/api'
import { DatasetFilter, DateRange } from '../lib/types'
import styled from "styled-components";

export const DateRangePicker = ({
  open,
  handleClose,
  setRange,
}: {
  open: boolean
  handleClose: () => void
  setRange: Dispatch<SetStateAction<DateRange>>
}) => {
  const [start, setStart] = useState<Date | null>(null)
  const [end, setEnd] = useState<Date | null>(null)

  const handleCancel = () => {
    setStart(null)
    setEnd(null)
    handleClose()
  }

  const handleConfirm = () => {
    const res = {}
    Object.assign(
      res,
      start && { start_date: start.toISOString().substring(0, 10) },
    )
    Object.assign(res, end && { end_date: end.toISOString().substring(0, 10) })
    setRange(res)

    handleClose()
  }

  const handleReset = () => {
    setRange({})
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose()
      }}
    >
      <DialogTitle>Select Date Range</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
            <DatePicker
              mask="__/__/____"
              label="Start Date"
              value={start}
              onChange={(newValue) => {
                setStart(newValue)
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
            <DatePicker
              mask="__/__/____"
              label="End Date"
              value={end}
              onChange={(newValue) => {
                setEnd(newValue)
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleReset}>Clear</Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}

export const FilterDatasets = ({
  open,
  handleClose,
  options,
}: {
  open: boolean
  handleClose: () => void
  options: DatasetFilter
}) => {
  const { updateFilter } = useAppContext()
  const [filter, setFilter] = useState<DatasetFilter>(options)

  const handleReset = () => {
    setFilter(options)
  }

  const clearAll = () => {
    setFilter([])
  }

  const handleFilterClose = () => {
    updateFilter(filter)
    handleClose()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setFilter([...filter, event.target.name])
    } else {
      setFilter(filter.filter((dataset) => dataset != event.target.name))
    }
  }

  return (
    <Dialog open={open} onClose={handleFilterClose}>
      <DialogTitle>Dataset Filter</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
        >
          <FormGroup>
            {options &&
              options.map((option, i) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filter.includes(option)}
                        onChange={handleChange}
                        name={option}
                      />
                    }
                    key={i}
                    label={option}
                  />
                )
              })}
          </FormGroup>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={clearAll}>Clear All</Button>
        <Button onClick={handleReset}>Check All</Button>
        <Button onClick={handleFilterClose}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}

export const SearchForm = () => {
  const { data: options } = useGetSchemas()
  const { updateQuery, updateFilter } = useAppContext()
  const [openDatePick, setOpenDatePick] = useState(false)
  const [openFilter, setOpenFilter] = useState(false)
  const [dateRange, setDateRange] = useState<DateRange>({})
  const [search_str, setSearchStr] = useState('')

  const handleDatePickClose = () => setOpenDatePick(false)
  const handleDatePickOpen = () => setOpenDatePick(true)
  const handleFilterClose = () => setOpenFilter(false)
  const handleFilterOpen = () => setOpenFilter(true)

  useEffect(() => {
    if (options) updateFilter(Object.keys(options))
  }, [updateFilter, options])

  const IndexWrapper = styled.div`
    .MuiInputBase-input.Mui-disabled {
      color: black;
      opacity: 1;
      -webkit-text-fill-color: black;
    }
  `;

  return (
    <>
      <TextField
        placeholder="Search"
        onChange={(e) => setSearchStr(e.target.value)}
        style={{ marginRight: '10px' }}
        size="small"
        sx={{
          input: { color: 'red', backgroundColor: '#fff', borderRadius: '5px' },
        }}
      />
      <IndexWrapper>
        <TextField
          disabled
          placeholder={dateRange ? dateRange.start_date : ''}
          style={{ width: '110px', marginRight: '10px' }}
          size="small"
          sx={{
            input: { color: 'red', backgroundColor: '#fff', borderRadius: '5px' },
          }}
        />
        <span>To</span>
        <TextField
          disabled
          placeholder={dateRange ? dateRange.end_date : ''}
          style={{ width: '110px', marginLeft: '10px' }}
          size="small"
          sx={{
            input: { color: 'red', backgroundColor: '#fff', borderRadius: '5px' },
          }}
        />
      </IndexWrapper>
      <IconButton color="inherit" onClick={handleDatePickOpen}>
        <Badge badgeContent={0} color="secondary">
          <EventRoundedIcon />
        </Badge>
      </IconButton>
      <DateRangePicker
        open={openDatePick}
        handleClose={handleDatePickClose}
        setRange={setDateRange}
      ></DateRangePicker>
      <IconButton
        color="inherit"
        onClick={() =>
          updateQuery(
            Object.assign(
              { ...dateRange },
              search_str != '' ? { search_str } : null,
            ),
          )
        }
      >
        <Badge badgeContent={0} color="secondary">
          <SearchIcon />
        </Badge>
      </IconButton>
      <IconButton color="inherit" onClick={handleFilterOpen}>
        <Badge badgeContent={0} color="secondary">
          <FilterAltIcon />
        </Badge>
      </IconButton>
      {options && (
        <FilterDatasets
          open={openFilter}
          handleClose={handleFilterClose}
          options={Object.keys(options)}
        ></FilterDatasets>
      )}
    </>
  )
}
