import { createContext, useContext, ReactNode, useState } from "react";
import { DatasetFilter } from "./lib/types";

type appContextType = {
    query: Record<string, string>;
    filter: DatasetFilter
    updateQuery: (q: Record<string, string>) => void
    updateFilter:  (f: DatasetFilter) => void
};

const authContextDefaultValues: appContextType = {
    query: {},
    filter: [],
    updateQuery: () => {return},
    updateFilter: () => {return}
};

const AppContext = createContext<appContextType>(authContextDefaultValues);

export function useAppContext() {
    return useContext(AppContext);
}

type Props = {
    children: ReactNode;
};

export function AppProvider({ children }: Props) {
    const [query = {}, setQuery] = useState<Record<string, string>>({});
    const [filter = [], setFilter] = useState<DatasetFilter>([])
    
    const value = {
        query,
        filter,
        updateQuery: setQuery,
        updateFilter: setFilter
    };

    return (
        <>
            <AppContext.Provider value={value}>
                {children}
            </AppContext.Provider>
        </>
    );
}